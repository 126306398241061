/**
 * Pinterest Pinit
 *
 * @see {@link root/gdpr-banner.js}
 */
(function (w, d) {
    w.onCookiePolicyAccepted(function () {
        var t = 'script',
            j = d.createElement(t),
            s = d.getElementsByTagName(t)[0];

        j.src = '//assets.pinterest.com/js/pinit.js';
        j.async = 1;
        j.defer = 1;
        j.setAttribute('data-pin-hover', 'true');
        j.setAttribute('data-pin-tall', 'true');
        s.parentNode.insertBefore(j, s);
    });
}(window, document));