/**
 * Accordion
 *
 * @requires libraries/util.js
 *
 * @see https://codyhouse.co/ds/components/app/accordion
 */

(function (w, d) {
    this.Accordion = function (element) {
        this.element = element;
        this.items = Util.getChildrenByClassName(this.element, 'js-accordion__item');
        this.showClass = 'is-open';
        this.animateHeight = (this.element.getAttribute('data-animation') === 'on');
        this.multiItems = !(this.element.getAttribute('data-multi-items') === 'off');
        this.initAccordion();
    };

    Accordion.prototype.initAccordion = function () {
        //set initial aria attributes
        for (var i = 0; i < this.items.length; i++) {
            var button = this.items[i].getElementsByTagName('button')[0],
                content = this.items[i].getElementsByClassName('js-accordion__panel')[0],
                isOpen = Util.hasClass(this.items[i], this.showClass);
            Util.setAttributes(button, {
                'aria-expanded': isOpen,
                'aria-controls': 'accordion-content-' + i,
                'id': 'accordion-header-' + i
            });
            Util.addClass(button, 'js-accordion__trigger');
            Util.setAttributes(content, {'aria-labelledby': 'accordion-header-' + i, 'id': 'accordion-content-' + i});
        }

        //listen for Accordion events
        this.initAccordionEvents();
    };

    Accordion.prototype.initAccordionEvents = function () {
        var self = this;

        this.element.addEventListener('click', function (event) {
            var trigger = event.target.closest('.js-accordion__trigger');
            //check index to make sure the click didn't happen inside a children accordion
            if (trigger && Util.getIndexInArray(self.items, trigger.parentElement) >= 0) self.triggerAccordion(trigger, event.target);
        });
    };

    Accordion.prototype.triggerAccordion = function (trigger, target) {
        var self = this;
        var bool = (trigger.getAttribute('aria-expanded') === 'true');

        this.animateAccordion(target, bool);
    };

    Accordion.prototype.animateAccordion = function (target, bool) {
        var self = this;
        var item = target.closest('.js-accordion__item'),
            content = item.getElementsByClassName('js-accordion__panel')[0];

        if (!bool) Util.addClass(item, this.showClass);
        target.setAttribute('aria-expanded', !bool);

        if (this.animateHeight) {
            //store initial and final height - animate accordion content height
            var initHeight = bool ? content.offsetHeight : 0,
                finalHeight = bool ? 0 : content.offsetHeight;
        }

        if (w.requestAnimationFrame && this.animateHeight) {
            Util.setHeight(initHeight, finalHeight, content, 200, function () {
                self.resetContentVisibility(item, content, target, bool);
            });
        } else {
            self.resetContentVisibility(item, content, target, bool);
        }

        if (!this.multiItems && !bool) this.closeSiblings(item);

    };

    Accordion.prototype.resetContentVisibility = function (item, content, target, bool) {
        Util.toggleClass(item, this.showClass, !bool);
        content.removeAttribute("style");
    };

    Accordion.prototype.closeSiblings = function (item) {
        //if only one accordion can be open -> search if there's another one open
        var index = Util.getIndexInArray(this.items, item);
        for (var i = 0; i < this.items.length; i++) {
            if (Util.hasClass(this.items[i], this.showClass) && i !== index) {
                this.animateAccordion(this.items[i].getElementsByClassName('js-accordion__trigger')[0], true);
                return false;
            }
        }
    };

    //initialize the Accordion objects
    var accordions = d.getElementsByClassName('js-accordion');
    if (accordions.length > 0) {
        for (var i = 0; i < accordions.length; i++) {
            (function (i) {
                new Accordion(accordions[i]);
            })(i);
        }
    }
}(window, document));