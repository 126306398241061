/**
 * Search
 *
 * Clean URL e effetto Overlay Loney Planet
 *
 * @author Daniele De Nobili
 */

(function (w) {
    // Clean URL
    function handleSearch(form) {
        form.addEventListener('submit', function (event) {
            event.preventDefault();

            var url = form.action + 'search/' + encodeURI(form.s.value).replace(/%20/g, '+') + '/';
            var qs = '';

            form.querySelectorAll('input, select, textarea').forEach(function (field) {
                if (field.name === 's') {
                    return;
                }

                qs += field.name + '=' + encodeURI(form[field.name].value) + '&';
            });

            if (qs) {
                qs = qs.substr(0, qs.length - 1);
                url += '?' + qs;
            }

            w.location = url;
        });
    }
}(window));
