/**
 * Brand
 *
 * @requires plugins/swiper.js
 */
(function () {

    new Swiper('.js-testimonials', {
        spaceBetween: 0,
        slidesPerView: 1,
        //mousewheel: true,
        grabCursor: true,
        setWrapperSize: false,
        autoHeight: true,
        autoplay: {
            delay: 10000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

}());