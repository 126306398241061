/**
 * Advanced Search
 *
 * @author Daniele De Nobili
 */

(function (d) {
    "use strict";

    d.querySelectorAll('[name="s"]').forEach(function (inputElement) {

        autocomplete({
            input: inputElement,
            fetch: function (query, update) {
                query = query.toLowerCase();

                fetch(`/wp-json/advanced-search/v1/suggestions?search=${query}`)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (data) {
                        data = data.map(function (result) {
                            return {
                                label: result
                            };
                        });

                        update(data);
                    });
            },
            onSelect: function(item) {
                inputElement.value = item.label;
            }
        });
    });
}(document));
