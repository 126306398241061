/**
 * YouTube Subscribe Button
 *
 * @see {@link root/gdpr-banner.js}
 */
(function (w, d) {
    w.onCookiePolicyAccepted(function () {
        var t = 'script',
            j = d.createElement(t),
            s = d.getElementsByTagName(t)[0];

        j.src = 'https://apis.google.com/js/platform.js';
        j.async = 1;
        j.defer = 1;
        s.parentNode.insertBefore(j, s);
    });
}(window, document));